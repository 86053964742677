<template>
    <div>
        <b-modal
        title="Product Image View"
        hide-footer
        centered
        size="lg"
        ref="imageModal"
        class="img_modal"
        >
        <div class="pro_img_cont">
            <img :src="imageUrl" class="pro_img"/>
        </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
export default{
    components:{
        BModal
    },
    props:['imageUrl'],
    methods:{
        showModal(){
            this.$refs.imageModal.show();
        }
    }
}
</script>

<style scoped>
.pro_img_cont{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.pro_img{
    width: 100%;
    height: 100%;
}
.img_modal{
    width: 60rem;
    height: 70rem;
}
</style>