<template>
  <div class="product_desc_root">
    <div class="product_description">
      <div class="description_left">
        <div class="d_left_inner" v-if="product">
          <div v-for="(ob, i) in product.productImages" :key="i" @click="imageClickedHandler(ob)">
            <div class="images-img" v-if="ob"
              :class="[(selectedImage === ob || (selectedImage == '' && i == 0)) ? 'clickedImg' : '']">
              <img class="small-img" :src="ob" />
            </div>
          </div>
        </div>
        <div class="selected-image-container" v-if="product" @click="showImageInZoom">
          <img class="selected-image-small" :src="selectedImage || product.productImages[0]" />
          <ImageModal :imageUrl="selectedImage || product.productImages[0]" ref="imageModal" />
        </div>
      </div>
      <div class="description_right">
        <div class="title">{{ product ? product.name : "" }}</div>
        <div class="description">{{ product ? product.description : "" }}</div>
        <div class="price">
          <div class="actual-price">
            ₹
            {{
              selectedVariant ? selectedVariant.priceOffer ? selectedVariant.priceOffer : selectedVariant.priceOriginal : ''
            }} </div>
          <div class="cut-p"
            v-if="selectedVariant && selectedVariant.priceOffer && selectedVariant.priceOffer < selectedVariant.priceOriginal">
            ₹ {{ selectedVariant ? selectedVariant.priceOriginal : "" }}
          </div>
        </div>
        <div class="calc">
          <div class="spc" @click="quantityChangeHandler('-')"><span>-</span></div>
          <div class="spx"><span>{{ quantity }}</span></div>
          <div class="spc" @click="quantityChangeHandler('+')"><span>+</span></div>
        </div>
        <hr class="custom_hr" />
        <div class="x">
          <div class="mid-label">
            Select {{ selectedVariant ? selectedVariant.title : "" }}
          </div>
          <div class="calc__">

            <select v-model="selectedVariant" class="spx-first">
              <option v-for="item in product.variants" :key="item.id" :value="item">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="selectedVariant && selectedVariant.inventoryQuantity && selectedVariant.inventoryQuantity <= 5"
          class="less-quantity-line">
          Hurry up! only {{ selectedVariant.inventoryQuantity }} left in stock..
        </div>
        <div class="store_btns" v-if="selectedVariant">
          <b-button class="add_to_cart_btn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="addToCart" :disabled="!selectedVariant.inventoryQuantity">
            {{ selectedVariant.inventoryQuantity ? 'ADD TO CART' : 'OUT OF STOCK' }}

          </b-button>
          <b-button class="buy_now_btn" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="buyNow"
            :disabled="!selectedVariant.inventoryQuantity">
            BUY NOW
          </b-button>
        </div>
        <div class="descr-head">Product Details:-</div>
        <div class="description_">Description:-</div>
        <div class="descr-c">
          {{ product ? product.description : "" }}
        </div>
      </div>
    </div>
    <div class="similar_actegories_products" v-if="similarCategoryProducts.length > 0">
      <div
        style="margin-top: 2rem; display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 1rem;">
        <div class="similar_pro_text">Similar Products</div>
      </div>
      <div style="overflow-x: scroll;">
        <div v-if="similarCategoryProducts" class="similar_products__">
          <div v-for="n in similarCategoryProducts">
            <Item :val="n" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ImageMagnifier } from 'vue-image-magnifier';
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BDropdown, BDropdownItem,

} from "bootstrap-vue";
import dummyImg from '../../../assets/yicimages/store/desc-page.png'
import ImageModal from './ProductImageModal.vue'
// ../../../assets//yicimages/store/desc-page.png
import yicLogo from '../../../assets/yicimages/logo/YourIndianCart_logo-1-color.jpg';
import Item from './Item.vue';

export default {
  components: {
    ImageMagnifier,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BDropdown, BDropdownItem,
    ImageModal,
    Item

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // pics: [1, 2, 3],
      indx: 1,
      quantity: 1,
      showDismissibleAlert: false,
      selectedVariant: null,
      selectedImage: '',
      isItemInCart: false
    };
  },
  methods: {
    clickHandler(nam) {
      this.$router.push({ name: nam });
    },
    imageClickedHandler(i) {
      this.selectedImage = i;
    },
    quantityChangeHandler(operation) {
      if (operation === "-" && this.quantity > 1) this.quantity--;
      else if (operation === "+" && (this.quantity + 1 <= this.selectedVariant.inventoryQuantity)) this.quantity++;
    },
    ...mapActions({
      fetchProduct: "storeProducts/fetchProduct",
      fetchProductVariants: "storeProducts/fetchProductVariants",
      addItemToCart: "storeShoppingCartItems/addItemToCart",
      setShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
      createOrder: "storeShoppingCartItems/createOrderForDirectPurchase",
      createRazorpayPaymentsSuccess: "storeShoppingCartItems/createRazorpayPaymentsSuccessForDirectPurchase",
      setProductsForSimilarCategories: "storeProducts/setProductsForSimilarCategories",
      getVariants: "storeProducts/getTempVariant",
      getItemInCart: "storeShoppingCartItems/getItemInCart",
    }),
    async addToCart() {
      // if(this.isItemInCart){
      //   this.$router.push({name: 'cart'})
      //   return;
      // }
      const productId = this.$route.params.id;
      const quan = this.quantity;
      const variantId = this.selectedVariant.id;
      const anonymousUserId = localStorage.getItem("anonymousUserId");
      const userId = this.currUser ? this.currUser.id : null;
      await this.addItemToCart({ quan, productId, variantId, anonymousUserId, userId });
      console.log("userID", userId);
      await this.setShoppingCartItems({ userId });
      this.showDismissibleAlert = true;
      this.$swal({
        icon: 'success',
        title: 'Item added to cart successfully',
      }).then(function () {
      });
    },
    async buyNow() {
      const price = this.selectedVariant ? this.selectedVariant.priceOffer ? this.selectedVariant.priceOffer : this.selectedVariant.priceOriginal : 0
      await this.createOrder({ price: price * this.quantity });
      if (this.paymentGatewayOption == 'CCAvenue') {
        this.ccAvenuePaymentHandler();
      }
      else {
        this.isOrderCreated = true;
        this.razorpayPaymentHandler();
      }
    },
    // CCAvenue Paymnt handler
    async ccAvenuePaymentHandler() {
      // await this.createOrder();
      this.$router.push({
        name: "ccAvenueCheckout",
        params: {
          merchantId: this.ccAvenueResponse.merchantId,
          accessCode: this.ccAvenueResponse.accessCode,
          encReq: this.ccAvenueResponse.encryptedOrderData
        },
      });
    },
    // Razorpay payment Handler
    async razorpayPaymentHandler() {
      const this__ = this;
      const price = this.selectedVariant ? this.selectedVariant.priceOffer ? this.selectedVariant.priceOffer : this.selectedVariant.priceOriginal : 0
      // const price =  * 100;
      // console.log('************', this.storeState.finalPrice);
      var options = {
        key: this.storeState.razorpayKeyId, // Enter the Key ID generated from the Dashboard
        amount: price * this.quantity, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Your Indian Cart",
        description: "Test Transaction",
        image: yicLogo,
        // "https://www.rugastech.com/wp-content/uploads/2018/07/rugas-3.png",
        order_id: this.storeState.razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          this__.createRazorpayPaymentsSuccess({
            razorpayOrderId: response.razorpay_order_id,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            variant: this__.selectedVariant,
            quantity: this__.quantity
          });
          this__.paymentSucessHandler();
        },
        prefill: {
          name: this.userDetails.firstName,
          email: this.userDetails.email,
          contact: this.userDetails.contactNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        "modal": {
          "ondismiss": function () {
            // console.log('Checkout form closed');
          }
        },
        retry: false
      };
      var rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", async function (response) {
        this__.showFailureAlert();
      });
      // this.setIsLoading(false);
      rzp1.open();
    },
    async paymentSucessHandler() {
      this.showSuccessAlert();
    },
    showSuccessAlert() {
      // Use sweetalert2
      const this__ = this;
      this.$swal({
        icon: 'success',
        title: 'Payment Sucessful',
        text: 'Thanks for purchasing from YIC! You can find your products in Dashboard. You will be redirected to Store',
        confirmButtonText: 'Continue'
      }).then(function () {
        this__.$router.push({ name: 'store' });
      });
    },
    showFailureAlert() {
      // Use sweetalert2
      const this__ = this;
      this.$swal({
        icon: 'error',
        title: 'Payment Failed',
        text: 'Try checkout again, you will be redirected to the cart',
        confirmButtonText: 'Continue'
      })
        .then(function () {
          // this__.$router.push({ name: 'cart' });
        });
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    showImageInZoom() {
      this.$refs.imageModal.showModal()
    },
    async findItemInCart() {
      let anonymousUserId = null;
      if(!localStorage.getItem("anonymousUserId")){
        anonymousUserId = null
      }
      else{
        anonymousUserId = localStorage.getItem("anonymousUserId");
      }
      const userId = this.currUser ? this.currUser.id : null;
      const res = await this.getItemInCart({ productId: this.selectedVariant.products[0].id, variantId: this.selectedVariant.id, anonymousUserId: anonymousUserId, userId: userId })
      if (res.data.length > 0)
        this.isItemInCart = true;
      return res
    }
  },
  computed: mapState({
    product(state) {
      return state.storeProducts.product;
    },
    productVariants(state) {
      return state.storeProducts.productVariants;
    },
    currUser(state) {
      return state.login.currUser;
    },
    paymentGatewayOption: state => {
      return state.storeShoppingCartItems.paymentGatewayOption
    },
    ccAvenueResponse(state) {
      return state.storeShoppingCartItems.ccAvenueResponse;
    },
    orderId(state) {
      return state.storeShoppingCartItems.orderId;
    },
    storeState(state) {
      return state.storeShoppingCartItems;
    },
    userDetails: (state) => {
      return state.basicprofilemodule;
    },
    similarCategoryProducts: state => {
      return state.storeProducts.similarCategoryProducts
    }
  }),
  watch: {
    // productVariants(newSt, oldSt) {
    //   this.selectedVariant = newSt[0];
    // },
  },
  async mounted() {
    const itemId = this.$route.params.id;
    await this.fetchProduct({id: itemId, fetchVariants: true});
    this.selectedVariant = this.product && this.product.variants[0] && this.product.variants[0]
    // await this.fetchProductVariants(itemId);
    await this.setProductsForSimilarCategories({ similarCategories: this.product.productCategory[0].similarCategories })
    const promise = this.product.productCategory[0].similarCategories.map(async cat => {
      await this.getVariants({ selectedCategory: cat });
    })
    await Promise.all(promise);
    this.findItemInCart()
  },
};
</script>

<style scoped>
.bttn1 {
  background: #e87613;
  color: #ffffff;
  width: 475px;
  height: 40px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

.price {
  font-weight: 700;
  font-size: 28px;
  color: black;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.root-desc {
  display: flex;
  gap: 10rem;
  margin: 2rem 8rem;
}

.mid-label {
  font-weight: 600;
  font-size: 18px;
  color: #414141;
}

.calc>span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mid {
  display: flex;
}



.descr-head {
  font-weight: 500;
  font-size: 1.5rem;
  color: #414141;
}

.descr-c {
  width: 475px;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}



.images {
  display: flex;
}




.text-side {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  /* justify-content: space-between; */
  /* height: 70vh; */
}

.und {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  margin-left: 1.5rem;
}

.alert_body__ {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cut-p {
  color: #555;
  ;
  text-decoration: line-through;
  font-weight: 500;
  display: inline-block;
  font-size: 18px;
}



.sale {
  display: inline-block;
  border-radius: 30px;
  background-color: #4385f5;
  color: white;
  padding: 16px 8px;
}

.text_sale {
  font-size: 13px;
}

.kop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  margin-top: 2rem;
}

.sm {
  display: none;
}

.less-quantity-line {
  color: red;
}

.product_description {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 2rem;
  max-height: 100vh;
}

.product_description>* {
  margin: 2rem;
}

.product_desc_root {
  width: 100vw;
  height: 100%;
}

.description_left {
  width: 40%;
  height: 50%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.description_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: auto;
}

div::-webkit-scrollbar {
  display: none;
}

.d_left_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
}

.images-img {
  max-width: 5rem;
  max-height: 6rem;
  border-radius: 0.5rem;
  cursor: pointer;
  object-fit: contain;
}

.small-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}

.clickedImg {
  border-radius: 0.5rem;
  border: 1px solid orange;
  object-fit: contain;
}

.selected-image-container {
  width: 35rem;
  height: 26rem;
  border-radius: 0.5rem;
  object-fit: contain;
}

.selected-image {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  object-fit: contain;
}

.selected-image-small {
  display: visible;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  object-fit: contain;
}

.description {
  font-size: 1rem;
  color: #737373;
  font-weight: 400;
}



.spc {
  color: #000;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spx {
  color: #000;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_hr {
  height: 2px;
  color: #414141;
  width: 100%;
}

.img_magnifier {
  border-radius: 1rem;
}

.store_btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.store_btns>* {
  margin-right: 1rem;
  margin-bottom: 2rem;
}

.add_to_cart_btn1 {
  border: 1px solid #FF6F00;
  border-radius: 1.5rem;
  color: #FF6F00;
  width: 50%;
}

.buy_now_btn {
  background: #FF6F00;
  color: white;
  border-radius: 1.5rem;
  width: 50%;
}

.x {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calc {
  border: 1.5px solid #D9D9D9;
  border-radius: 2rem;
  width: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.calc__ {
  border: 1.5px solid #D9D9D9;
  border-radius: 1.5rem;
  width: 10rem;
}

.spx-first {
  width: 100%;
  border-radius: 1.5rem;
  border: none;
  padding: 0.5rem;
}

.description_ {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
}

.blue_view_all {
  color: #4385F5;
}

.similar_pro_text {
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 1rem;
}

.similar_actegories_products {
  padding: 2rem;
}

.similar_products__ {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 3rem;
  overflow-x: scroll;
}

/*========================= Media Queries (Small devices) =========================*/

@media screen and (max-width: 600px) {
  .root-desc {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    margin: 0 1rem;
  }

  .selected-image-container {
    width: 100%;
    height: 15rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #ebebeb; */
  }

  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .title {
    display: none;
  }

  .sm {
    display: flex;
    font-size: 20px;
    margin: 1rem 0rem;
  }

  /*  */


  .images-img {
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 1rem;
    background: #e8e8e8;
  }

  .img-side {
    width: 100%;
  }

  .descr-c {
    width: auto;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
  }

  .sm-btn {
    /* position: fixed; */
    /* bottom: 0; */
    margin: 0 1rem;
    width: 90%;
  }

  .price {
    margin-top: 2rem;
  }

  .alert_body__ {
    flex-direction: column;
  }

  .selected-image {
    display: none;
  }

  .selected-image-small {
    display: unset;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }


}
</style>

<!-- alert designs -->
<style>
#alert__ {
  background: #e87613 !important;
}

#alert__>.close {
  color: #ffffff !important;
}

.image-magnifier>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-magnifier {
  height: 100%;
  width: 100%;
}
</style>